@import "variables";

.box-shadow-hover {
  transition: box-shadow .2s ease;

  &:hover {
    box-shadow: 0 0 3px 3px $color-gray;
  }
}

.kpi-box-shadow-hover {
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow: 0 3px 8px 5px $color-gray;
    transform: translate(-5px, -5px);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.cursor-default {
  cursor: default !important;
}

.text-link-info {
  color: $info !important;
  cursor: pointer;

  &:hover {
    color: darken($info, 10%) !important;
    text-decoration: underline;
  }
}

.text-dark-gray {
  color: $color-dark-gray;
}

.text-dark-gray-hover {
  color: $color-dark-gray;

  &:hover {
    color: darken($color-dark-gray, 20%);
  }
}

.text-underline {
  text-decoration: underline;
}

.text-purple {
  color: $color-purple;
}

.btn-link {
  color: $color-isupplier;
  cursor: pointer;

  &:hover {
    color: darken($color-isupplier, 10%);
  }

  &:active {
    color: darken($color-isupplier, 15%);
  }
}

.btn-outline-warning:hover, .btn-outline-warning:active, .btn-outline-warning:focus {
  color: white !important;
}

.modal-center {
  top: 25%;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.max-hint {
  color: #95a5a6;
  letter-spacing: 0;
  font-size: 80%;
}

.break-word {
  word-break: break-word;
}

.white-space-nowrap {
  white-space: nowrap;
}
